<script setup>
import {ref, computed} from "vue"
import {RouterLink} from "vue-router"
import {Button} from "../shadcn-vue/button"
import {Loader2, ChevronRight} from "lucide-vue-next"

const props = defineProps({
  label: {
    type: String,
  },
  route: {
    type: [Boolean, String, Object],
    default: false,
  },
  icon: {
    type: [Boolean, Function],
    default: false,
  },
  align: {
    type: String,
    default: "center",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  progress: {
    type: Boolean,
    default: false,
  },
  doubleAction: {
    type: Boolean,
    default: false,
  },
  doubleActionLabel: {
    type: String,
    default: "confirm",
  },
  chevron: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["click"])

const doubleActionCounter = ref(0)

const disabledLocal = computed(() => props.disabled || props.progress)

const as = computed(() => {
  if (props.route) {
    return RouterLink
  } else {
    return "Button"
  }
})

const to = computed(() => {
  if (typeof props.route === "string") {
    return props.route
  } else if (typeof props.route === "object") {
    return props.route
  } else {
    return undefined
  }
})

const labelLocal = computed(() => {
  if (props.doubleAction && doubleActionCounter.value === 1) {
    return props.doubleActionLabel
  } else {
    return props.label
  }
})

function onClick() {
  if (props.disabled) {
    return
  }
  if (props.doubleAction) {
    if (doubleActionCounter.value === 1) {
      emit("click")
      doubleActionCounter.value = 0
    } else {
      doubleActionCounter.value++
    }
  } else {
    emit("click")
  }
}
</script>

<template>
  <Button :as :to :disabled="disabledLocal" :align @click="onClick">
    <!-- progress -->
    <Loader2 class="size-4 mr-2 animate-spin" v-if="progress" />

    <!-- icon -->
    <component
      :is="icon"
      class="size-5"
      :class="[{'mr-1.5': labelLocal}]"
      v-if="icon" />

    <!-- label -->
    <span class="">{{ labelLocal }}</span>

    <!-- slot -->
    <slot></slot>

    <!-- chevron -->
    <div class="grow flex justify-end" v-if="chevron">
      <ChevronRight class="size-5" />
    </div>
  </Button>
</template>
