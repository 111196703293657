import { request } from '../request.js'

export const providersCreate = async (data, opts) => {
  return request({
    endpoint: 'providers',
    method: 'POST',
    data: { data },
    opts
  })
}

export const providersGet = async (providerId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append('include', opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ''

  const endpoint = providerId ? `providers/${providerId}${query}` : `providers${query}`

  return request({
    endpoint,
    method: 'GET',
    opts
  })
}

export const providersUpdate = async (providerId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}`,
    method: 'PATCH',
    data: { data },
    opts
  })
}

export const providersDelete = async (providerId, opts) => {
  return request({
    endpoint: `providers/${providerId}`,
    method: 'DELETE',
    opts
  })
}

// educations
export const providersEducationsCreate = async (providerId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/educations`,
    method: 'POST',
    data: { data },
    opts
  })
}

export const providersEducationsGet = async (providerId, educationId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append('include', opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ''

  const endpoint = educationId
    ? `providers/${providerId}/educations/${educationId}${query}`
    : `providers/${providerId}/educations${query}`

  return request({
    endpoint,
    method: 'GET',
    opts
  })
}

export const providersEducationsUpdate = async (providerId, educationId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/educations/${educationId}`,
    method: 'PATCH',
    data: { data },
    opts
  })
}

export const providersEducationsDelete = async (providerId, educationId, opts) => {
  return request({
    endpoint: `providers/${providerId}/educations/${educationId}`,
    method: 'DELETE',
    opts
  })
}

// licenses
export const providersLicensesCreate = async (providerId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/licenses`,
    method: 'POST',
    data: { data },
    opts
  })
}

export const providersLicensesGet = async (providerId, licenseId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append('include', opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ''

  const endpoint = licenseId
    ? `providers/${providerId}/licenses/${licenseId}${query}`
    : `providers/${providerId}/licenses${query}`

  return request({
    endpoint,
    method: 'GET',
    opts
  })
}

export const providersLicensesUpdate = async (providerId, licenseId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/licenses/${licenseId}`,
    method: 'PATCH',
    data: { data },
    opts
  })
}

export const providersLicensesDelete = async (providerId, licenseId, opts) => {
  return request({
    endpoint: `providers/${providerId}/licenses/${licenseId}`,
    method: 'DELETE',
    opts
  })
}

// insurances
export const providersInsurancesCreate = async (providerId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/insurances`,
    method: 'POST',
    data: { data },
    opts
  })
}

export const providersInsurancesGet = async (providerId, insuranceId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append('include', opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ''

  const endpoint = insuranceId
    ? `providers/${providerId}/insurances/${insuranceId}${query}`
    : `providers/${providerId}/insurances${query}`

  return request({
    endpoint,
    method: 'GET',
    opts
  })
}

export const providersInsurancesUpdate = async (providerId, insuranceId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/insurances/${insuranceId}`,
    method: 'PATCH',
    data: { data },
    opts
  })
}

export const providersInsurancesDelete = async (providerId, insuranceId, opts) => {
  return request({
    endpoint: `providers/${providerId}/insurances/${insuranceId}`,
    method: 'DELETE',
    opts
  })
}

// identifications
export const providersIdentificationsCreate = async (providerId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/identifications`,
    method: 'POST',
    data: { data },
    opts
  })
}

export const providersIdentificationsGet = async (providerId, identificationId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append('include', opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ''

  const endpoint = identificationId
    ? `providers/${providerId}/identifications/${identificationId}${query}`
    : `providers/${providerId}/identifications${query}`

  return request({
    endpoint,
    method: 'GET',
    opts
  })
}

export const providersIdentificationsUpdate = async (providerId, identificationId, data, opts) => {
  return request({
    endpoint: `providers/${providerId}/identifications/${identificationId}`,
    method: 'PATCH',
    data: { data },
    opts
  })
}

export const providersIdentificationsDelete = async (providerId, identificationId, opts) => {
  return request({
    endpoint: `providers/${providerId}/identifications/${identificationId}`,
    method: 'DELETE',
    opts
  })
}

export const providersSendScheduleSlotsReminder = async (providerId, opts) => {
  return request({
    endpoint: `providers/${providerId}/send-schedule-slots-reminder`,
    method: 'POST',
    opts
  })
}
