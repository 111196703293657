import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {auth} from "@aleahealth/common/apps/firebase/index.js"
import {onAuthStateChanged} from "firebase/auth"
import {initApi} from "@aleahealth/common"
import {useAppStore} from "@aleahealth/common/apps/stores/app.js"
import {useUserStore} from "@aleahealth/common/apps/stores/user.js"
import {useProfileStore} from "@aleahealth/common/apps/stores/profile.js"

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  let user = auth.currentUser
  if (requiresAuth && !user) {
    return {name: "AuthView"}
  }
})

// router.afterEach((to, from) => {})

onAuthStateChanged(auth, async (firebaseUser) => {
  console.log("onAuthStateChanged", firebaseUser)
  const appStore = useAppStore()
  const userStore = useUserStore()
  const profileStore = useProfileStore()
  const app = appStore.app

  // Wait for the router to be ready before accessing the current route
  await router.isReady()

  const currentRoute = router.currentRoute.value
  const currentRouteName = currentRoute.name
  const currentRouteRequiresAuth = currentRoute.meta.requiresAuth || false

  if (firebaseUser?.uid) {
    // console.log("signed in")
    userStore.userSignedIn = true
    userStore.userAuth = {
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
    }

    // get ACL claims from token
    const token = await firebaseUser.getIdTokenResult()
    const claims = token?.claims
    const acl = claims?.acl || {}
    userStore.userAcl = acl
    // console.log({acl})

    // re-init api
    initApi({appId: "client", firebaseUser})

    // get user data
    await userStore.get({userId: firebaseUser.uid})

    // get user profile
    await profileStore.get({id: userStore.user?.profile?.id})

    const redirectedFrom = currentRoute?.redirectedFrom

    console.log({redirectedFrom, currentRouteName})

    // redirected? -> follow redirect
    if (redirectedFrom && redirectedFrom.fullPath !== "/") {
      console.log("redirecting to:", redirectedFrom)
      router.push(redirectedFrom)
    } else {
      // join questionnaire view -> stay
      if (["JoinView", "JoinIntroView", "JoinQuestionnaireView"].includes(currentRouteName)) {
        // if non-anonymous profile is signed in -> kick out
        if (firebaseUser.email) {
          console.log("non-anonymous profile signed in -> redirecting to dashboard")
          router.push({name: "DashboardView"})
        }
      }
      // anonymous profile -> join questionnaire view
      else if (!firebaseUser.email) {
        router.push({name: "JoinQuestionnaireView", params: {role: acl.role}})
      }
      // fallback
      else {
        // go where supposed to go
        router.push({
          name: "DashboardView",
        })
      }
    }
  }
  // on sign out
  else if (currentRouteRequiresAuth && currentRouteName !== "AuthView") {
    console.log("signed out -> redirecting to AuthView")
    userStore.user = {}
    userStore.userAuth = {}
    userStore.userAcl = {}
    userStore.userSignedIn = false
    router.push({
      name: "AuthView",
    })
  }

  // mock some delay on dev machine
  if (import.meta.env.VITE_ENVIRONMENT === "local_dev") {
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
  app.auth.initialUserCheckDone = true
})

export {router}
