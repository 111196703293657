import {createApp} from "vue"
import {router} from "./router/index.js"
import App from "./App.vue"
import {createPinia} from "pinia"
import "@aleahealth/common/apps/assets/index.css"
import * as Sentry from "@sentry/vue"
import VueGtag from "vue-gtag"

const urlParams = new URLSearchParams(window.location.search)

// disable ga_tracking on this machine
const disableTracking = urlParams.get("disableTracking")
if (disableTracking) {
  const config = {ga_tracking: false}
  localStorage.setItem("@aleahealth/config", JSON.stringify(config))
}

window.app = {
  version: "__VERSION__",
  build: "__BUILD__",
  commit: "__RELEASE_SHA__",
  date: "__BUILD_DATE__",
}

const pinia = createPinia()
const app = createApp(App)

const currentEnvironment = import.meta.env.VITE_ENVIRONMENT

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],
  environment: currentEnvironment,
  // Tracing
  tracesSampleRate: currentEnvironment == "production" ? 1.0 : 0,
  tracePropagationTargets: [
    "https://aleahealth-development.firebaseapp.com",
    "https://aleahealth-production.firebaseapp.com",
  ],
  // Session Replay
  replaysSessionSampleRate: currentEnvironment == "production" ? 0.5 : 0,
  replaysOnErrorSampleRate: 1,
})

app.use(pinia)
app.use(router)

if (currentEnvironment == "production") {
  app.use(
    VueGtag,
    {
      appName: "AleaHealth",
      pageTrackerScreenviewEnabled: true,
      includes: [
        {
          id: "AW-16676577262",
        },
      ],
      config: {id: "G-RXJPF2HDJ4"},
    },
    router
  )
}

app.mount("#app")
