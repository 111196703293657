import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {providersGet, clientsGet} from "@aleahealth/common"
import {useUserStore} from "./user.js"

export const useProfileStore = defineStore("profile", () => {
  const userStore = useUserStore()

  // state
  const profile = ref({})
  const status = ref("init")

  // getters
  const profileLoaded = computed(() => status.value == "loaded")
  const profileId = computed(() => profile.value?.id)

  // actions
  async function get({id} = {}) {
    const role = userStore.user.role
    console.log("profile.js store | profile id:", id)
    const res =
      role === "client"
        ? await clientsGet(id, {notifySuccess: false})
        : await providersGet(id, {notifySuccess: false})
    profile.value = res.response.data
    status.value = "loaded"
  }

  return {
    // state
    profile,
    status,

    // getters
    profileLoaded,
    profileId,

    // actions
    get,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
