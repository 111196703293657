import {request} from "../request.js"

export const clientsCreate = async (data, opts) => {
  return request({
    endpoint: "clients",
    method: "POST",
    data: {data},
    opts,
  })
}

export const clientsGet = async (clientId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = clientId ? `clients/${clientId}${query}` : `clients${query}`

  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const clientsUpdate = async (clientId, data, opts) => {
  return request({
    endpoint: `clients/${clientId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const clientsDelete = async (clientId, opts) => {
  return request({
    endpoint: `clients/${clientId}`,
    method: "DELETE",
    opts,
  })
}
