<script setup>
import {computed, watch} from "vue"
import {useRoute} from "vue-router"
import AleaViewMenuDesktop from "./AleaViewMenuDesktop.vue"
import AleaViewMenuMobile from "./AleaViewMenuMobile.vue"
import {X, ArrowLeft} from "lucide-vue-next"
import AleaButton from "./AleaButton.vue"
import {useAppStore} from "../../stores/app.js"
import {useUserStore} from "../../stores/user.js"
import {storeToRefs} from "pinia"
import AleahealthIconPrimary from "../../assets/img/aleahealth-icon-primary.svg?url"

const props = defineProps({
  menuItems: {
    type: Array,
    required: true,
  },
  classesMainView: {
    type: String,
    default: "",
  }
})

const appStore = useAppStore()
const userStore = useUserStore()
const route = useRoute()

const {app} = storeToRefs(appStore)

const {userIsClient, userIsProvider} = storeToRefs(userStore)

const layoutConfig = computed(() => route.meta.layout)

const showBackButton = computed(() =>
  route.meta.backRouteName && route.meta.backRouteName !== route.name
    ? true
    : false
)
const menuItemsEnriched = computed(() => {
  return props.menuItems.map((item) => {
    return {
      ...item,
      active: route.matched.some((r) => r.name === item.route.name),
    }
  })
})

function setBodyBackground(background) {
  document.body.classList.remove("bg-orange-25")
  document.body.classList.remove("bg-gradient-alea")
  document.body.classList.remove("bg-white")
  document.body.classList.remove("bg-dark")

  if (background === "orange") {
    document.body.classList.add("bg-orange-25")
  } else if (background === "gradient") {
    document.body.classList.add("bg-gradient-alea")
  } else if (background === "dark") {
    document.body.classList.add("bg-neutral-900")
  } else {
    document.body.classList.add("bg-white")
  }
}

watch(
  layoutConfig,
  (newVal) => {
    setBodyBackground(newVal?.main?.background)
  },
  {immediate: true}
)
</script>

<template>
  <div
    class="flex w-full min-h-dvh"
    :class="[{'md:pl-[250px]': layoutConfig?.navbar !== false}]">
    <!-- menu / sidebar -->
    <AleaViewMenuDesktop
      :menuItemsEnriched
      v-if="layoutConfig?.navbar !== false" />
    <AleaViewMenuMobile
      :menuItemsEnriched
      v-if="layoutConfig?.navbar !== false" />

    <!-- main -->
    <div class="flex flex-col relative grow">
      <header
        class="sticky top-0 right-0 left-0 md:left-[250px] z-10 border-b bg-white px-4 py-2 min-h-14 flex flex-row justify-center shadow-lg shadow-neutral-500/5"
        :class="{
          'md:hidden':
            layoutConfig?.headerMobileOnly ||
            (layoutConfig?.headerHideOnDesktopProvider && userIsProvider),
        }"
        v-if="layoutConfig?.header">
        <!-- header:slot -->
        <component
          :is="app.header.slot.component"
          v-bind="app.header.slot.props"
          v-if="
            app.header?.slot?.component && layoutConfig?.header === 'slot'
          " />

        <!-- header:default -->
        <div
          class="flex items-center justify-center gap-1"
          v-if="layoutConfig?.header !== 'slot'">
          <!-- alea icon -->
          <img
            :src="AleahealthIconPrimary"
            class="absolute left-4 size-8 md:hidden"
            v-if="!showBackButton" />

          <!-- back button -->
          <AleaButton
            class="absolute left-2"
            :icon="ArrowLeft"
            size="icon"
            variant="naked"
            :route="{name: $route.meta.backRouteName, params: $route.params}"
            v-if="showBackButton" />

          <!-- title -->
          <h2 class="text-xl font-semibold">{{ $route.meta.pageTitle }}</h2>

          <!-- slot (for buttons) -->
          <div class="absolute right-4">
            <component
              :is="app.header.slot.component"
              v-bind="app.header.slot.props"
              v-if="app.header?.slot?.component" />
          </div>
        </div>
      </header>

      <!-- main view -->
      <main
        class="_min-h-dvh flex flex-col pb-24 md:pb-0 w-screen md:w-full h-full"
        :class="[
          {'px-0': !layoutConfig?.main?.padding},
          {'px-4': layoutConfig?.main?.padding},
          classesMainView
        ]">
        <RouterView />
      </main>
    </div>

    <!-- sidebar -->
    <div
      class="md:border-l md:border-neutral-200 md:w-96 w-full absolute inset-0 md:static bg-white z-10 min-h-dvh"
      v-show="app.sidebar.open">
      <!-- close button -->
      <span
        class="block md:hidden fixed top-4 left-4 z-20 text-sm font-semibold cursor-pointer bg-white rounded-full p-2 shadow-md shadow-black/5"
        @click="app.sidebar.open = false"
        ><X class="size-5"
      /></span>

      <component
        :is="app.sidebar.slot.component"
        v-bind="app.sidebar.slot.props"
        v-if="app.sidebar?.slot?.component" />
    </div>
  </div>
</template>
