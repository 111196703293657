<script setup>
import AleaAvatar from "./AleaAvatar.vue"
import AleaButton from "./AleaButton.vue"
import AleahealthIconPrimary from "../../assets/img/aleahealth-icon-primary.svg"
import AleahealthLogoPrimary from "../../assets/img/aleahealth-logo-primary.svg"
import {LogOut} from "lucide-vue-next"
import {useAppStore} from "../../stores/app.js"
import {useUserStore} from "../../stores/user.js"
import {storeToRefs} from "pinia"
import {auth} from "@aleahealth/common/apps/firebase/index.js"
import {signOut} from "firebase/auth"
import {computed, ref, onMounted} from "vue"

const props = defineProps({
  menuItemsEnriched: {
    type: Array,
    required: true,
  },
})

const appStore = useAppStore()
const userStore = useUserStore()
const {app} = storeToRefs(appStore)
const {user} = storeToRefs(userStore)

const elAvatar = ref(null)

const truncatedName = computed(() => {
  if (!user.value?.name) return
  const maxLength = 20
  const name = user.value.name
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + "..."
  }
  return name
})

const truncatedEmail = computed(() => {
  if (!user.value?.email) return
  const maxLength = 20
  const email = user.value.email
  if (email.length > maxLength) {
    return email.slice(0, maxLength) + "..."
  }
  return email
})

onMounted(() => {
  app.value.menuDesktop = {
    ...app.value.menuDesktop,
    elAvatar: elAvatar.value,
  }
})
</script>

<template>
  <aside
    class="inset-y fixed left-0 z-20 h-full flex-col md:border-r border-neutral-100 hidden md:flex w-[250px] bg-white shadow-lg shadow-black/5">
    <!-- logo -->
    <div class="p-2 h-14 flex items-center">
      <AleahealthLogoPrimary class="ml-3 h-8 w-auto" />
    </div>

    <!-- menu desktop top -->
    <nav class="grid gap-1 p-2">
      <AleaButton
        :variant="item.active ? 'ghostSelected' : 'ghost'"
        align="left"
        :icon="item.icon"
        :label="item.title"
        :route="item.route"
        class="rounded-lg flex flex-row gap-1 items-center"
        v-for="item in menuItemsEnriched"
        :key="item.id" />
    </nav>

    <!-- menu desktop bottom -->
    <nav class="mt-auto grid gap-1 p-2">
      <div class="flex flex-row gap-3 items-center">
        <AleaAvatar ref="elAvatar" :user="user" class="size-8" />
        <div class="flex flex-col">
          <span class="block font-bold text-sm">{{ truncatedName }}</span>
          <span class="text-neutral-400 text-xs">{{ truncatedEmail }}</span>
        </div>
        <AleaButton
          variant="ghost"
          size="icon"
          class="text-red-500"
          :icon="LogOut"
          @click="signOut(auth)" />
      </div>
    </nav>
  </aside>
</template>
